<template>
  <div class="container">
    <div class="row text-center">
      <div class="col-2 mx-auto">
        <h4 class="fw-bold">利用規約</h4>
      </div>
    </div>
    <div class="row text-start mt-4">
      <div class="col-6 mx-auto">
        <p>
          この利用規約（以下，「本規約」といいます。）は，公益財団法人日本テニス協会（以下，「当協会」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。

        </p>
      </div>
    </div>
    <div class="row text-center mt-2">
      <div class="col-3 mx-auto">
        <h5 class="fw-bold">第1条（適用）</h5>
      </div>
    </div>
    <div class="row text-first mt-5">
      <div class="col-6 mx-auto">
        <p>
          1.本規約は，ユーザーと当協会との間の本サービスの利用に関わる一切の関係に適用されるものとします。
        </p>
        <p class="mt-5">
          2.当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
        </p>
        <p class="mt-5">
          3.本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfService"
}
</script>

<style scoped>

</style>